.tooltip {
  width: fit-content;
  font-size: 1rem;
  line-height: 1;

  // override tooltip
  .__react_component_tooltip {
    text-align: left;
    font-size: 1rem;
    color: #25282a;
    @apply leading-normal;
    max-width: 260px;
    border-radius: 4px;
    padding: 1rem;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));

    &.show {
      opacity: 1;
    }
  }
}

.tooltip__override {
  @media (max-width: theme('screens.xs+.max')) {
    .__react_component_tooltip {
      left: 0 !important;
      &.show {
        left: 0 !important;
      }
    }
  }
}
