.dropdown {
  ul {
    min-width: 224px;
  }

  &-ul {
    max-height: 0;
    opacity: 0;
    transition: opacity 0.2s linear;
    box-shadow: 0 3px 12px 1px rgba(0, 0, 0, .15);
  }

  &-ul.active {
    max-height: 100%;
    opacity: 1;
  }
}
