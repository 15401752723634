.alert {
  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__text-group {
    align-items: center;
  }

  &__icon {
    @apply mr-3;
    display: flex;
  }

  &__icon.-animate {
    transform-origin: 9px 9px;
    animation: rotation 2s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  &__title,
  &__description {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &__title {
    font-weight: 700;
  }

  &__close {
    cursor: pointer;
  }

  &__btn {
    display: flex;
    align-items: center;
  }

  .email .email-span {
    line-height: 1.5;
    margin-top: 0;
  }
}
