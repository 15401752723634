.payment-card .accordion {
  &__title {
    @apply py-3;
  }

  &__icon {
    @apply static;
  }
}

.details-card {
  .funded-loan-details{
    margin-bottom: -16px;
  }

  .dlist-label {
    @apply text-sm;
    @screen lg {
      @apply text-base;
    }
  }

  .dlist-value {
    @apply text-sm;
    @apply font-bold;
    @screen lg {
      @apply text-base;
    }
  }

  .btn--primary {
    max-width: 280px;
  }
}
