.home-search {
  height: 341px;
}

.saved-home-tile {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 266px;
  width: 252px;
  border: 1px solid #f0f0f0;
  border-radius: 14px;
  margin-right: 15px;
  transition: box-shadow 0.2s;
}

.saved-home-tile:hover {
  box-shadow: 0 1rem 1.125rem 0.125rem rgb(0 0 0 / 12%);
}

.saved-home-tile:last-child {
  margin-right: 0;
}

.saved-homes-title {
  font-size: 18px;
  font-weight: bold;
}

.saved-home-image {
  height: 141px;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  background-color: #d4d4d4;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

@media (max-width: 815px) {
  .saved-home-tile:nth-child(3) {
    display: none;
  }
}

@media (max-width: 550px) {
  .saved-home-tile:nth-child(2) {
    display: none;
  }
}
