.sortable-table {
  .table-cell {
    @apply pr-1;
  }

  .table-cell:last-child {
    padding-right: 0;
  }
}

.Truncate__Override {
  p {
    overflow: visible;
    white-space: normal;
  }
}

.ow-anywhere {
  overflow-wrap: anywhere;
}
