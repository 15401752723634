/* Must match TRANSITION_DURATION defined in src/components/flyout/FlyoutComponent.tsx */
:root {
  --flyout-transition-duration: 200ms;
}

.Flyout__Body--open {
  overflow: hidden;
}

.Flyout__Overlay {
  transition-duration: var(--flyout-transition-duration);
  overflow: hidden;
  inset: 0;
}

.Flyout__Content {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  background: #fff;
  border-radius: 0;
  outline: none;
  transition-property: transform, opacity;
  transition-duration: var(--flyout-transition-duration);
  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  opacity: 1;
}

/* Order matters below. Switching css order could break animations. */

.Flyout__Content-left {
  left: 0;
  transform: translateX(-100%);
}

.Flyout__Content-right {
  right: 0;
  transform: translateX(100%);
}

.Flyout__Content--after-open {
  transform: translateX(0);
}

.Flyout__Content-left--before-close {
  transform: translateX(-100%);
}

.Flyout__Content-right--before-close {
  transform: translateX(100%);
}

/* Mobile / Tablet */
@media (max-width: theme('screens.md+.max')) {
  .Flyout__Content {
    width: 100%;
  }

  .Flyout__Overlay--before-close {
    transition-delay: 20ms;
    transition-duration: 230ms;
  }
}