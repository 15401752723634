.rate-alert {
  max-width: 100%;

  @screen lg {
    max-width: 312px;
  }

  .disclaimers-icon {
    transition: transform 0.1s ease-in;
    transform-origin: center center;
  }

  .disclaimers {
    .hover-effect {
      height: 1rem;
      width: 1rem;
    }
  }

  .disclaimers-shown {
    transform: scaleY(-1);
    margin-top: 6px;
  }
}
