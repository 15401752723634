.disclaimer {
  p, h3, h4, h5, ol, ul, table {
    @apply text-sm;
    @apply my-4;
  }

  p:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  ol:first-child,
  ul:first-child {
    margin-top: 0;
  }

  p:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  ol:last-child,
  ul:last-child {
    margin-bottom: 0;
  }
}
