.styleguide {
  /*
  h1 {
    @apply my-4;
    font-weight: bold;
  }

  h2 {
    @apply mb-2;
  }

  h3 {
    @apply mb-2;
  }

  hr {
    @apply my-8;
  }
  */

  &__alert {
    @apply my-3;
  }

  &__grid-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  &__icon {
    @apply my-3;
    cursor: pointer;
  }

  &__subtext {
    font-style: italic;
  }

  &__recommendations {
    &.-short {
      width: 240px;
    }
  }
}

.pallet {
  width: 192px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  box-shadow: 0 4px 16px 0 rgba(37, 40, 42, 0.15);

  &__color {
    border-radius: 12px 12px 0 0;
    height: 190px;
  }
}
