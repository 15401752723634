.note {
  font-weight: 700;
}

.lox-disclaimer > p {
  @apply text-xs-important;
}

.lox-disclaimer > p:last-child {
  @apply mb-2 !important;
}
