.email-phone-wrapper {
  min-width: 190px;
  white-space: break-spaces;
  word-break: break-word;
}

.email-phone-wrapper .email-button-icon {
  align-items: start;
}

.email-phone-wrapper .btn-text {
  align-items: start;
}

.email-phone-wrapper .btn-wrap {
  align-items: start;
}