.zoom-tile {
  transition: transform .5s ease;
  width: 98%;
  height: 98%;
  margin: 0 auto;
}

.zoom-tile:hover {
  transform: scale(1.02) !important;
}
