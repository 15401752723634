.home-feature-grid {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 48px));
  column-gap: 24px;

  &:last-child {
    @apply mb-0;
  }

  &__title {
    grid-column: 1 / span 3;
  }

  &__value {
    grid-column: 4 / span 3;
  }
}
