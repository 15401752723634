.error-header {
  font-size: 42px;
  line-height: 56px;
}

.error-body-lg {
  font-size: 20px;
  line-height: 28px;
}

.error-body-md {
  font-size: 16px;
  line-height: 24px;
}

.browser-icon {
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  margin-bottom: 5px;
}
