@screen md {
  .home-details {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 48px));
    column-gap: 24px;

    &__home-features-container {
      grid-column: 1 / span 6;
    }

    &__image-container {
      grid-column: 7 / span 6;
    }
  }
}
