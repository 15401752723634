/* Must match TRANSITION_DURATION defined in src/components/ui/modal/Modal.tsx */
:root {
  --modal-transition-duration: 100ms;
}

.modal-body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #fff;
  opacity: 0;
  overflow: auto;
  transition: opacity var(--modal-transition-duration) ease-in-out;
}

.modal {
  position: relative;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  transition: opacity var(--modal-transition-duration) ease-in-out;
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;

  .modal {
    opacity: 1;
  }
}

.ReactModal__Overlay--before-close {
  opacity: 0;

  .modal {
    opacity: 0;
  }
}

.modal__close {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
}

// Mobile / Tablet
@media (max-width: theme('screens.md+.max')) {
  .modal {
    min-height: 100%;
  }
}

// Desktop
@media (min-width: theme('screens.lg')) {
  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(4px);
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  }

  .modal-overlay {
    padding: 9vh 1.5rem 3rem;
  }

  .modal {
    max-width: 840px;
  }

  .modal__close {
    top: 14px;
    right: 14px;
  }
}
