.title {
  font-size: 1.875rem;
  line-height: 1.33;
}

.text {
  font-size: 1rem;
  line-height: 1.5;
}

.apple-store-image {
  width: 120px;
}

.google-play-image {
  width: 135px;
}

.background {
  height: 100%;
}

.title-text {
  @apply text-3xl;
}

.text-section {
  width: auto;
}

@screen md {
  .background {
    height: calc(100% - 15px);
  }
}

@screen lg {
  .background {
    height: calc(100% - 12px);
  }

  .text-section {
    width: 356px;
  }
}

/* full width ad4 styles */
.full-ad-title-text {
  @apply text-5xl;
}

.full-ad-background {
  height: calc(100% - 40px);
}

.full-ad-text-section {
  width: 475px;
}

.large-image {
  display: block;
}

.med-image {
  display: none;
}

.small-image {
  display: none;
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .full-ad-text-section {
    width: 356px;
  }

  .large-image {
    display: none;
  }

  .med-image {
    display: block;
    width: 568px;
  }

  .small-image {
    display: none;
  }
}

@media (max-width: 1023px) {
  .full-ad-text-section {
    width: auto;
  }

  .large-image {
    display: none;
  }

  .med-image {
    display: none;
  }

  .small-image {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .full-ad-background {
    height: 100%;
  }
}
/* full width ad4 styles */

@media (max-width: 590px) {
  .title-text {
    @apply text-2xl;
  }

  .full-ad-title-text {
    @apply text-3xl;
  }
}

@screen sm {
  .sm-title {
    font-size: 2.5rem;
    line-height: 1.2;
  }

  .sm-text {
    font-size: 1.625rem;
    line-height: 1.3;
  }

  .photo {
    width: 53%;
  }

  .sm-apple-store-image {
    width: 164px;
  }

  .sm-google-play-image {
    width: 182px;
  }
}

.rate-app-ad-2 {
  max-width: 346px;

  @screen lg {
    max-width: 312px;
  }
}