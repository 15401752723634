.payment-modal {
  &__container {
    height: 1300px; // extra for validation messages to extend height
    width: 100%;
    padding-left: 4rem; // center it a little
  }

  &__iframe {
    width: 100%;
    height: 1399px;
    min-width: 310px;
    max-width: 559px;
  }
}

// Mobile / Tablet
@media (max-width: theme('screens.md+.max')) {
  .payment-modal {
    &__container {
      padding-left: 0;
    }
  }
}
