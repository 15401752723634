.file-upload {
  .upload-text {
    font-size: 24px;
    line-height: 32px;
  }

  .submit-button {
    width: 100%;
  }

  .cancel-button {
    width: 100%;
  }

  @media (min-width: theme('screens.lg')) {
    .upload-text {
      font-size: 32px;
      line-height: 40px;
    }

    .submit-button {
      width: fit-content;
    }
  }
}
