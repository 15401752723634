.registration__container {
  max-width: 415px;

  .registration__form-group:nth-last-of-type(2) {
    margin-bottom: 24px;
  }

  .form-btn {
    margin-top: 0;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .registration__container {
    width: 415px;
    .registration__form-group,
    .form-btn {
      max-width: 432px;
    }
  }
}
