.home-value-search-skeleton-loading {
  .skeleton {
    display: block;
  }
}

.home-value-search {
  &__button {
    max-width: 280px;
    height: 52px;
  }

  .form-group-override {
    margin-bottom: 1.5rem;
  }

  .form-btn {
    margin-top: 8px;
  }

  .form-label[for='unit'] {
    white-space: nowrap;
    overflow: hidden;
  }
}
