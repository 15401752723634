.create-password {
  &__form-group-container {
    margin-bottom: 24px; // overrides .form-group
  }

  .form-group {
    margin-bottom: 0;
  }

  input {
    @apply pr-10;
  }

  &__show-hide {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 1;

    .icon-hide {
      font-size: 22px;
    }

    .icon-show {
      font-size: 16px;
    }
  }

  // overrides existing rules
  .form-btn,
  &__submission-error {
    margin-top: 0;
    width: 100%;
    max-width: 280px;
  }
}

@media (min-width: 992px) {
  .create-password {
    &__form-group-container,
    &__validation-criteria,
    &__symbols,
    &__submission-error,
    .form-btn {
      max-width: 432px;
    }
  }
}
