.payment-task-body {
  &__container {
    width: auto;
    height: 800px;
  }

  &__iframe {
    width: 100%;
    height: 100%;
  }

  // todo: @screen lg {}... causing lint error for at-rule-unknown
  @media (min-width: 992px) {
    &__container {
      width: 700px;
    }
  }
}
