.accordion {
  &__title {
    @apply py-4;
    text-align: left;
    @apply font-bold;
    @apply text-sm;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @screen lg {
      @apply text-base;
    }
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    background: #fff;
    @apply ml-4;
    transition-property: background-color;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    height: 2.5rem;
    width: 2.5rem;
    margin-top: -8px;
    margin-bottom: -8px;
  }

  &__icon {
    transition: transform 0.2s ease-in;
    transform-origin: center center;

    &--active {
      transform: scaleY(-1);
    }
  }

  &__content {
    p {
      margin-bottom: 1rem;
      @apply text-sm;
      @screen lg {
        @apply text-base;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
