.task-title {
  font-weight: 700;
  line-height: 1.5;
  @apply text-sm;

  @screen lg {
    @apply text-base;
  }
}

.task-button {
  @apply text-sm;

  @screen lg {
    @apply text-base;
  }
}

.task {
  &--collapsable {
    .task-title {
      @apply pr-6;
    }

    .accordion {
      &__container {
        padding-top: 11px !important;
      }
    }
  }
}