.marketing_disclosure_iframe {
  // for mobile view - full height minus padding top and bottom
  height: calc(100vh - 72px);
}

@media (min-width: theme('screens.lg')) {
  .marketing_disclosure_iframe {
    @apply h-96;
  }
}
