/* Must match TRANSITION_DURATION defined in src/components/ui/sliding-drawer/SlidingDrawer.tsx */
:root {
  --sliding-drawer-transition-duration: 200ms;
}

.Sliding__Drawer__Overlay {
  overflow: hidden;
  transform: translateX(100%);
  transition-property: transform, opacity;
  transition-duration: var(--sliding-drawer-transition-duration);
  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  opacity: 0;
  position: absolute;
  z-index: 1;
  inset: 0;
  width: 100%;
  height: calc(100% + 4.5rem); // 100% + footer top margin height
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  background-color: #fff;
}

.Sliding__Drawer__Overlay--after-open {
  transform: translateX(0);
  opacity: 1;
}

.Sliding__Drawer__Overlay--before-close {
  transform: translateX(100%);
  opacity: 0;
}

.Sliding__Drawer {
  height: 100%;
  overflow: hidden;
}

// Mobile & tablet
@media (max-width: theme('screens.md+.max')) {
  .Sliding__Drawer__Body--open {
    overflow: hidden;
  }

  .Sliding__Drawer__Overlay {
    border: 0;
    position: fixed;
    inset: 0;
    top: 0;
    left: 0;
    height: 100%;
  }
}