.waiting-for-tasks {
  @apply mx-auto;
  @apply pb-2;
  @apply px-2;

  &__content {
    height: 128px;
  }

  &__house {
    width: 280px;
    height: 258px;
    @apply mt-4;
    @apply mx-auto;

    .svg-sprite {
      width: 280px;
      height: 258px;
    }
  }

  @media (min-width: 768px) {
    &__house {
      width: 440px;
      height: 405px;

      .svg-sprite {
        display: block;
        width: 440px;
        height: 405px;
      }
    }
  }
}

.assistant-help {
  position: absolute;
  bottom: 40px;
  left: 20px;
  z-index: 100;
  display: flex;
  align-items: flex-end;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translate3d(0, 5%, 0);

  &-enter,
  &-enter-done {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &__avatar {
    margin-right: 20px;
    width: 72px;
    height: 72px;
    background: #fff;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 10px;
    box-shadow: 0 4px 16px rgba(37, 40, 42, 0.15);

    img {
      width: 100%;
      height: auto;
    }
  }

  &__status {
    position: absolute;
    right: 1px;
    top: 1px;
    animation: pulse 1s infinite ease-in-out;
  }

  &__text {
    position: relative;
    width: 230px;
    border: 1px solid #d8d8d8;
    background: #fff;
    box-shadow: 0 4px 16px rgba(37, 40, 42, 0.15);
    padding: 1.2rem 1rem;
    border-radius: 4px;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: 20px;
      left: 0;
      box-sizing: border-box;
      border: 8px solid transparent;
      border-color: transparent transparent #fff #fff;
      transform-origin: 0 0;
      transform: rotate(45deg);
      box-shadow: -1px 1px 0 0 rgba(37, 40, 42, 0.12);
    }

    p {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: theme('screens.lg')) {
    bottom: 50px;
    left: -30px;
  }

  @media (min-width: theme('screens.xl')) {
    bottom: 50px;
    left: -160px;
  }
}

.overlay-waiting-for-tasks {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

#modal-waiting-for-tasks {
  overflow: visible !important;

  @media (min-width: 990px) {
    max-width: 720px;
    border: 0 !important;
    border-radius: 12px;
    box-shadow: 0 4px 24px 4px rgba(37, 40, 42, 0.25);
  }
}

.animating-text-container {
  position: relative;
  width: 100%;

  @media (min-width: 990px) {
    overflow-x: hidden;
  }
}

.animating-text {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
  transition: opacity 0.4s ease, transform 0.4s ease;
  max-width: 625px;

  &-enter,
  &-enter-done {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &-exit,
  &-exit-done {
    visibility: visible;
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  &-exit-done {
    visibility: hidden;
  }

  &:first-child:not(.animating-text-exit) {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &:first-child.animating-text-exit-done {
    visibility: hidden;
  }
}